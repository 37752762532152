import {
   type MarketingSearchClinician,
   marketingSearchCliniciansSchema,
   type MarketingInitialDataResponse,
   type MarketingSearchCliniciansResponse,
   type MarketingCliniciansAvailabilityResponse,
   arrayOfNumbersField,
   marketingCliniciansAvailabilityByMonthSchema,
   type MarketingCliniciansAvailabilityByMonthResponse,
   marketingClinicianAvailabilityByDaySchema,
   type MarketingClinicianAvailabilityByDayResponse,
   WelkinEncounterTemplateNames,
   type MarketingAvailableServicesResponse,
   MarketingClinicianSoonestAvailabilityResponse,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const marketing = c.router(
   {
      getInitialData: {
         method: 'GET',
         path: '/marketing/initial-data',
         responses: {
            200: c.type<MarketingInitialDataResponse>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      searchClinicians: {
         method: 'GET',
         path: '/marketing/search-clinicians',
         query: marketingSearchCliniciansSchema,
         responses: {
            200: c.type<MarketingSearchCliniciansResponse>(),
         },
      },
      getCliniciansAvailability: {
         method: 'GET',
         path: '/marketing/clinicians-availability',
         query: z.object({
            timetapIds: arrayOfNumbersField,
            templateName: z.nativeEnum(WelkinEncounterTemplateNames).optional(),
         }),
         responses: {
            200: c.type<MarketingCliniciansAvailabilityResponse>(),
         },
      },
      getClinicianAvailabilityByMonth: {
         method: 'GET',
         path: '/marketing/clinicians-availability-by-month',
         query: marketingCliniciansAvailabilityByMonthSchema,
         responses: {
            200: c.type<MarketingCliniciansAvailabilityByMonthResponse>(),
         },
      },
      getClinicianAvailabilityByDay: {
         method: 'GET',
         path: '/marketing/clinicians-availability-by-day',
         query: marketingClinicianAvailabilityByDaySchema,
         responses: {
            200: c.type<MarketingClinicianAvailabilityByDayResponse>(),
         },
      },
      getClinician: {
         method: 'GET',
         path: '/marketing/clinician',
         query: z.object({
            welkinId: z.string(),
         }),
         responses: {
            200: c.type<MarketingSearchClinician>(),
         },
      },
      getAvailableServices: {
         method: 'GET',
         path: '/marketing/available-services',
         query: z.object({
            stateSlug: z.string(),
         }),
         responses: {
            200: c.type<MarketingAvailableServicesResponse>(),
         },
      },
      getSoonestAvailableClinicians: {
         method: 'GET',
         path: '/marketing/soonest-available-clinicians',
         query: z.object({
            stateSlug: z.string(),
         }),
         responses: {
            200: c.type<MarketingClinicianSoonestAvailabilityResponse>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
